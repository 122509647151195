import {ReactComponent as NumberThumb } from "assets/icons/number.svg"



export const numberType = {
    name: "number",
    title: "Number",
    // preview: <HeadingPreview/>,
    icon: <NumberThumb />,
}
