import { ReactComponent as HeadingThumb } from "assets/icons/heading.svg"




export const headingType = {
    name: "heading",
    title: "Heading",
    // preview: <HeadingPreview/>,
    icon: <HeadingThumb/>,
}
