import {ReactComponent as ImageThumb } from "assets/icons/imageThumb.svg"



export const imageType = {
    name: "image",
    title: "Image",
    // preview: <HeadingPreview/>,
    icon: <ImageThumb />,
}
